import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#fff",
      background: "#F0FFF3",
      backgroundSecondary: "#d7e0d7",
      border: "#62bc98",
      color: "#2c3844",
      colorButton: "white",
      hoverBackground: "#d7ffde",
    },
  },
  typography: {
    fontFamily: `'Karla','Overpass'`,
    h1: {
      fontSize: "32px",
      fontWeight: 600,
      color: "#2c3844",
    },
    h3: {
      fontSize: "30px",
      fontWeight: 700,
      color: "#2c3844",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#2c3844",
    },
    h6: {
      fontWeight: 600,
      color: "#2c3844",
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#2c3844",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#636d79",
    },
    body3: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#636d79",
    },
  },
  components:{
    MuiLink:{
      styleOverrides:{
        root:{
          color:"#2c3844",
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000",
      background: "#424e47",
      backgroundSecondary: "#333d38",
      border: "#b8e0cc",
      color: "#f0f7ff",
      hoverBackground: "#809289",
      colorButton: "white",
    },
  },
  typography: {
    fontFamily: `'Karla','Overpass'`,
    h1: {
      fontSize: "30px",
      fontWeight: 600,
      color: "#f0f7ff",
    },
    h3: {
      fontSize: "30px",
      fontWeight: 700,
      color: "#f0f7ff",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#f0f7ff",
    },
    h6: {
      fontWeight: 600,
      color: "#f0f7ff",
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#f0f7ff",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#c4ccd6",
    },
    body3: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#c4ccd6",
    },
  },
  components:{
    MuiLink:{
      styleOverrides:{
        root:{
          color:"#f0f7ff",
        }
      }
    }
  }
});

export { lightTheme, darkTheme };
