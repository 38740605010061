import { Button, Typography, Box,useMediaQuery } from "@mui/material";
import { useTheme } from "../../../theme/themeContext";
import Icons from "../../../MuiIcons/MuiIconsList";
import { useLanguage } from "../../../localization/languageContext";
import { useNavigate } from "react-router-dom";

export default function CardRoomType({ props }) {
  const { theme } = useTheme();
  const icons = Icons();
  const { title, description, guestNum, icon, bedsNum,scroll} = props;
  const { language } = useLanguage();
  const isVerySmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const navigate = useNavigate();
  return (
    <Box
      className="room-item-main-page"
      sx={{
        border: `1px solid ${theme.palette.primary.border}`,
        px: 1,
        width: { xs: "310px", sm: "370px" },
      }}
    >
      {icon}
      <Typography variant="h4" sx={{ mt: 1 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 1,
        }}
      >
        {description}
      </Typography>
      <div className="short-info-room-item">
        {icons.roomIcons.guestIcon}
        <Typography
          variant="body2"
          sx={{
            ml: 0.5,
          }}
        >
          {guestNum} {language.mainPage.typeRoomSec.guest} |
        </Typography>
        <Box sx={{ ml: 0.5 }}>{icons.roomIcons.bedIcon}</Box>

        <Typography
          variant="body2"
          sx={{
            ml: 0.5,
          }}
        >
          {bedsNum}{" "}
          {bedsNum === 1
            ? language.mainPage.typeRoomSec.bed
            : language.mainPage.typeRoomSec.beds}
        </Typography>
      </div>

      <Button
        variant="outlined"
        onClick={() => {
          navigate("/rooms");
          setTimeout(
            () => window.scrollTo({ top: isVerySmall ? scroll.xs : scroll.lg, behavior: "smooth" }),
            [300]
          );
        }}
        sx={{
          mt: "auto",
          mb: 3.5,
          color: theme.palette.primary.color,
          borderColor: theme.palette.primary.border,
          ":hover": {
            backgroundColor: theme.palette.primary.hoverBackground,
          },
        }}
      >
        {language.mainPage.typeRoomSec.buttonText}
      </Button>
    </Box>
  );
}
