import React, { useState } from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "../theme/themeContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Link,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { useLanguage } from "../localization/languageContext";

export default function Header() {
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage();

  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isVerySmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const [open, setOpen] = useState(false);
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const [curLangText, setCurLangText] = useState(() => {
    const l = localStorage.getItem("language");
    const newLang = l === "ukr" ? "ua" : "en";
    return newLang;
  });
  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  const createLabel = (path) => {
    switch (path) {
      case "/":
        return language.header.nav.mainText;
      case "/rooms":
        return language.header.nav.roomsText;
      case "/gallery":
        return language.header.nav.galleryText;
      case "/rules":
        return language.header.nav.rulesText;
      case "/contacts":
        return language.header.nav.contactsText;
      default:
        break;
    }
  };

  const isActive = (path) => location.pathname === path;
  const switchColor = (isActive) =>
    isActive ? "#e6b84f" : theme.palette.primary.color;

  const smallCase = () => (isSmall || isVerySmall ? false : true);

  return (
    <Box
      component="header"
      className="flex-center"
      sx={{
        height: "74px",
        px: { xs: 1, sm: 2, md: 1.5 },
        py: 2,
        backgroundColor: theme.palette.primary.background,
        position: "relative",
      }}
    >
      <Typography
        onClick={() => navigate("/")}
        variant="h1"
        sx={{
          fontFamily: "Sacramento",
          fontSize: "24px",
          ml: 2,
          mr: "auto",
          scale: 1.3,
          cursor: isActive("/") ? "default" : "pointer",
        }}
      >
        Harmony
      </Typography>
      <Box className="flex-center" sx={{ gap: { xs: 1.5, sm: 2, md: 1.5 } }}>
        {smallCase() && (
          <Button
            variant="outlined"
            sx={{
              border: 0.5,
              borderColor: theme.palette.primary.border,
              borderRadius: "30px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.23)",
              ":hover": {
                borderColor: theme.palette.primary.border,
                backgroundColor: theme.palette.primary.hoverBackground,
              },
              p: 1,
            }}
            onClick={toggleTheme}
            aria-label="Toggle theme"
          >
            {theme.palette.mode === "light" ? (
              <ModeNightIcon sx={{ color: "black" }} />
            ) : (
              <LightModeIcon sx={{ color: "white" }} />
            )}
          </Button>
        )}

        {/* Navigation */}
        {smallCase() === false ? (
          <Box className="flex-center menuIcon" onClick={toggleDrawer(true)}>
            <MenuIcon
              fontSize="large"
              sx={{
                color: theme.palette.primary.color,
                cursor: "pointer",
                transform: "scale(1.3)",
              }}
            />
          </Box>
        ) : (
          <Box className="header-links-box flex-center">
            {["/", "/rooms", "/gallery", "/rules", "/contacts"].map(
              (path, index) => (
                <Link
                  href={path}
                  key={index}
                  style={{
                    color: switchColor(isActive(path)),
                  }}
                >
                  {createLabel(path)}
                </Link>
              )
            )}
          </Box>
        )}
        {smallCase() && (
          <Button
            variant="outlined"
            onClick={() => setOpenLangMenu((prev) => !prev)}
            sx={{
              minWidth: "36px",
              padding: "4px",
              color: theme.palette.primary.color,
              borderColor: theme.palette.primary.border,
              ":hover": {
                borderColor: theme.palette.primary.border,
                backgroundColor: theme.palette.primary.hoverBackground,
              },
            }}
          >
            {curLangText}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() =>
            (window.location.href = "https://www.booking.com/Share-xy9bKD")
          }
          sx={{
            minWidth: "36px",
            padding: "8px",
            ":hover": {
              borderColor: theme.palette.primary.border,
              backgroundColor: theme.palette.primary.hoverBackground,
            },
          }}
        >
          {language.header.buttonText}
        </Button>

        {openLangMenu && (
          <ul
            className="flex-center header-lang-list"
            style={
              curLangText === "en"
                ? {
                    right: "5.4rem",
                  }
                : { right: "8.3rem" }
            }
          >
            <li
              onClick={() => {
                toggleLanguage("ukr");
                setCurLangText("ua");
                setOpenLangMenu(false);
              }}
            >
              ua
            </li>
            <li
              onClick={() => {
                toggleLanguage("eng");
                setCurLangText("en");
                setOpenLangMenu(false);
              }}
            >
              en
            </li>
          </ul>
        )}
      </Box>

      {/*hidden objects*/}
      {/* Drawer section */}
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ width: { xs: 200, sm: 250 } }}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          className="header-links-box flex-center"
          sx={{
            width: { xs: 200, sm: 250 },
            height: "100%",
            marginInline: 0,
            backgroundColor: theme.palette.primary.backgroundSecondary,
            flexDirection: "column",
            mt: "auto",
          }}
        >
          {["/", "/rooms", "/gallery", "/rules", "/contacts"].map(
            (path, index) => (
              <Link
                href={path}
                key={index}
                style={{
                  color: switchColor(isActive(path)),
                  fontSize: 18,
                }}
              >
                {createLabel(path)}
              </Link>
            )
          )}
          {!smallCase() && (
            <Button
              variant="outlined"
              sx={{
                border: 0.5,
                borderColor: theme.palette.primary.border,
                borderRadius: "30px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.23)",
                ":hover": {
                  borderColor: theme.palette.primary.border,
                  backgroundColor: theme.palette.primary.hoverBackground,
                },
                p: 1,
              }}
              onClick={toggleTheme}
              aria-label="Toggle theme"
            >
              {theme.palette.mode === "light" ? (
                <ModeNightIcon sx={{ color: "black" }} />
              ) : (
                <LightModeIcon sx={{ color: "white" }} />
              )}
            </Button>
          )}

          <Box
            className="flex-center menu-lang-list"
            sx={{
              gap: 2,
            }}
          >
            <Typography
              variant="body1"
              onClick={() => {
                toggleLanguage("ukr");
                setCurLangText("ua");
              }}
            >
              ua
            </Typography>
            <Typography
              variant="body1"
              onClick={() => {
                toggleLanguage("eng");
                setCurLangText("en");
              }}
            >
              en
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
