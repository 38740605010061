import AirlineSeatIndividualSuiteIcon from "@mui/icons-material/AirlineSeatIndividualSuite";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import HotelIcon from "@mui/icons-material/Hotel";
import PersonIcon from "@mui/icons-material/Person";
import BedIcon from "@mui/icons-material/Bed";
import CountertopsIcon from "@mui/icons-material/Countertops";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LivingIcon from '@mui/icons-material/Living';
import { useTheme } from "../theme/themeContext";
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LocalParkingIcon from '@mui/icons-material/LocalParking';

export default function Icons() {
  const { theme } = useTheme();

  const icons = {
    roomIcons: {
      economRoomIcon: (
        <AirlineSeatIndividualSuiteIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 }}
        />
      ),
      comfortRoomIcon: (
        <HotelIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 }}
        />
      ),
      luxRoomIcon: (
        <NightShelterIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 }}
        />
      ),
      guestIcon: (
        <PersonIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color }}
        />
      ),
      bedIcon: (
        <BedIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color, }}
        />
      ),
      areaIcon:(
        <AspectRatioIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color,  }}
        />
      ),
      roomQuantityIcon:(
        <LivingIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color,  }}
        />
      ),
      TVIcon:(
        <ConnectedTvIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color, }}
        />
      ),
      drinkWater:(
        <LocalDrinkIcon
          fontSize="small"
          sx={{ color: theme.palette.primary.color,  }}
        />
      )
    },
    prosIcons: {
      kitchenIcon: (
        <CountertopsIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 ,scale:2}}
        />
      ),
      placeIcon: (
        <PlaceIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 ,scale:2}}
        />
      ),
      parkingIcon: (
        <LocalParkingIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, mt: 3 ,scale:2}}
        />
      ),
    },
    footerIcons: {
      phoneIcon: (
        <PhoneIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color, }}
        />
      ),
      emailIcon: (
        <EmailIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color }}
        />
      ),
      locationIcon: (
        <PlaceIcon
          fontSize="large"
          sx={{ color: theme.palette.primary.color,scale:"0.8"}}
        />
      ),
    },
  };

  return icons;
}
