import Footer from "./partials/Footer";
import Header from "./partials/Header";
import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "./theme/themeContext";
import { useLanguage } from "./localization/languageContext";
import MainPhotoSection from "./partials/MainPhotoSection";

export default function Contacts() {
  const { theme } = useTheme();
  const { language } = useLanguage();
  return (
    <>
      <Header />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <MainPhotoSection>{language.contactsPage.title}</MainPhotoSection>
        {/* Contact section */}
        <Box
          className="contacts-contact-section"
          sx={{
            width: "100%",
            px: 5,
            py: 15,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(3, 0.3fr)",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 5, lg: 3 },
            backgroundColor: theme.palette.primary.backgroundSecondary,
          }}
        >
          <div>
            <Typography variant="h3">
              {language.contactsPage.contactSec.adress.title}
            </Typography>
            <Typography variant="body2">
              {language.contactsPage.contactSec.adress.adressText}
            </Typography>
          </div>
          <div>
            <Typography variant="h3">
              {language.contactsPage.contactSec.phone.title}
            </Typography>
            <Box
              className="footer-links-cont"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Link href="tel:+380935096949">+38 (093) 509 69 49</Link>
              <Link href="tel:+380995262777">+38 (099) 526 27 77</Link>
            </Box>
          </div>
          <div>
            <Typography variant="h3">
              {language.contactsPage.contactSec.email.title}
            </Typography>
            <Box
              className="footer-links-cont"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Link href="mailto:hotelharmony.kyiv@gmail.com">hotelharmony.kyiv@gmail.com</Link>
            </Box>
          </div>
        </Box>
        {/* google maps section */}
        <Box
          className="flex-center"
          sx={{
            width: "100%",
            backgroundColor: theme.palette.primary.background,
            py: 4,
          }}
        >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d284.1085085685115!2d30.515757392727725!3d50.45942537095617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce69a1ef7d8d%3A0x855275f22ca9c7f3!2sHarmony%20on%20Andrew%20descent!5e1!3m2!1sru!2sua!4v1727352348866!5m2!1sru!2sua"
          width="98%"
          height="450px"
          style={{ border: "0" }}
          title="Harmony map"
          loading="lazy"
        ></iframe>
        </Box>
      </Box>

      <Footer />
    </>
  );
}
