import Header from "./partials/Header";
import { useEffect, useRef } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "./theme/themeContext";
import CardRoomType from "./partials/landingPage/CardRoomType/CardRoomType";
import Icons from "./MuiIcons/MuiIconsList";
import Carousel from "./partials/Carousel";
import CardPros from "./partials/landingPage/CardPros/CardPros";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Footer from "./partials/Footer";
import { useLanguage } from "./localization/languageContext";
import { photosArray } from "../photos/photosArray";



export default function Main() {
  const { theme } = useTheme();
  const contentRef = useRef();
  const icons = Icons();
  const { language } = useLanguage();

  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isVerySmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  console.log(isSmall);
  const slides = isLarge ? 4 : isMedium ? 3 : isSmall ? 2 : 1;

  useEffect(() => {
    const addVisibility = () => {
      if (contentRef.current) {
        setTimeout(() => {
          Array.from(contentRef.current.children).forEach((child) => {
            child.classList.add("visible");
          });
        }, 900);
      }
    };

    addVisibility();
  }, [theme.palette.mode]);

  useEffect(() => {
    const vanishEffect = () => {
      if (contentRef.current) {
        const scrollPosition = window.scrollY;
        Array.from(contentRef.current.children).forEach((elem) =>
          scrollPosition > 400
            ? elem.classList.add("scroll-vanish")
            : elem.classList.remove("scroll-vanish")
        );
      }
    };
    window.addEventListener("scroll", vanishEffect);

    return () => window.removeEventListener("scroll", vanishEffect);
  }, []);

  useEffect(() => {
    const addGoToTopBtn = () => {
      const scrollPosition = window.scrollY;
      const element = document.querySelector(".go-up-button");

      if (element) {
        scrollPosition > 200
          ? element.classList.add("visible-up-button")
          : element.classList.remove("visible-up-button");
      }
    };

    window.addEventListener("scroll", addGoToTopBtn);

    return () => window.removeEventListener("scroll", addGoToTopBtn);
  }, []);

  return (
    <Box className="wrapper">
      <Header />
      {/* go up button */}
      <div
        className="go-up-button"
        style={{
          display: isSmall || isVerySmall ? "none" : "block",
          backgroundColor:
            theme.palette.mode === "light" ? "#2c3844a2" : "#f0f7ffa4",
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <KeyboardArrowUpIcon fontSize="large" sx={{ scale: 1.5 }} />
      </div>

      {/* Welcome section */}
      <Box
        sx={{
          backgroundAttachment: { xs: "scroll", md: "fixed" },
        }}
        className="entry-photo-cont"
        ref={contentRef}
      >
        <Box
          className="logo-text flex-center"
          sx={{
            width: { xs: "320px", sm: "500px" },
            backgroundColor: theme.palette.primary.background,
            transition: "opacity 1s ease-out, background-color 2s ease",
          }}
        >
          <Typography variant="h1" sx={{ fontSize: { xs: 26, sm: 34 } }}>
            Harmony Mini Hotel
          </Typography>
        </Box>
      </Box>
      {/* About hotel section*/}
      <Box
        className="flex-center"
        sx={{
          px: { lg: 20, sm: 5, xs: 1.5 },
          py: { lg: 22, sm: 15, xs: 8 },
          backgroundColor: theme.palette.primary.background,
          flexDirection: "column",
        }}
      >
        <Typography variant="h1">
          {language.mainPage.aboutHotelSec.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            width: "60%",
            textAlign: "center",
            lineHeight: { xs: 1.25, sm: 1.6 },
          }}
        >
          {language.mainPage.aboutHotelSec.paragraph}
        </Typography>

        {/* <Button
          variant="outlined"
          sx={{
            mt: 5,
            color: theme.palette.primary.color,
            borderColor: theme.palette.primary.border,
            ":hover": {
              backgroundColor: theme.palette.primary.hoverBackground,
            },
          }}
        >
          {language.mainPage.aboutHotelSec.buttonText}
        </Button> */}
      </Box>
      {/* Type rooms section */}
      <Box
        className="flex-center"
        sx={{
          backgroundColor: theme.palette.primary.backgroundSecondary,
          flexDirection: { lg: "row", xs: "column" },
          gap: 6,
          px: { xs: 0, sm: 10 },
          py: 15,
        }}
      >
        <CardRoomType
          props={{
            icon: icons.roomIcons.economRoomIcon,
            title: language.mainPage.typeRoomSec.economy.title,
            description: language.mainPage.typeRoomSec.economy.description,
            guestNum: 2,
            bedsNum: 1,
            scroll: { xs: 350, lg: 180 },
          }}
        />
        <CardRoomType
          props={{
            icon: icons.roomIcons.comfortRoomIcon,
            title: language.mainPage.typeRoomSec.comfort.title,
            description: language.mainPage.typeRoomSec.comfort.description,
            guestNum: 2,
            bedsNum: 1,
            scroll: { xs: 1500, lg: 750 },
          }}
        />
        <CardRoomType
          props={{
            icon: icons.roomIcons.luxRoomIcon,
            title: language.mainPage.typeRoomSec.lux.title,
            description: language.mainPage.typeRoomSec.lux.description,
            guestNum: "3+",
            bedsNum: 2,
            scroll: { xs: 2100, lg: 1000 },
          }}
        />
      </Box>
      {/* Carousel section */}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundColor: theme.palette.primary.background,
          py: 2,
        }}
      >
        <Carousel slidesPerView={slides}>{photosArray}</Carousel>
      </Box>
      {/* Pros section */}
      <Box
        className="flex-center"
        sx={{
          backgroundColor: theme.palette.primary.backgroundSecondary,
          flexDirection: { lg: "row", xs: "column" },
          gap: 8,
          py: 10,
          px: { xs: 0, sm: 10 },
        }}
      >
        <CardPros
          props={{
            icon: icons.prosIcons.placeIcon,
            title: language.mainPage.prosSec.locationText.title,
            description: language.mainPage.prosSec.locationText.description,
          }}
        />
        <CardPros
          props={{
            icon: icons.prosIcons.kitchenIcon,
            title: language.mainPage.prosSec.kitchenZoneText.title,
            description: language.mainPage.prosSec.kitchenZoneText.description,
          }}
        />
        <CardPros
          props={{
            icon: icons.prosIcons.parkingIcon,
            title: language.mainPage.prosSec.washingText.title,
            description: language.mainPage.prosSec.washingText.description,
            additional: language.mainPage.prosSec.washingText.notice,
          }}
        />
      </Box>
      {/* google maps section */}
      <Box
        className="flex-center"
        sx={{
          backgroundColor: theme.palette.primary.background,
          py: 4,
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d284.1085085685115!2d30.515757392727725!3d50.45942537095617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce69a1ef7d8d%3A0x855275f22ca9c7f3!2sHarmony%20on%20Andrew%20descent!5e1!3m2!1sru!2sua!4v1727352348866!5m2!1sru!2sua"
          width="98%"
          height="450px"
          style={{ border: "0" }}
          title="Harmony map"
          loading="lazy"
        ></iframe>
      </Box>
      <Footer />
    </Box>
  );
}
