import { useState, useContext, createContext } from "react";
import { eng, ukr } from "./languages";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Инициализация состояния языка из localStorage или использование дефолтного значения
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage === "ukr" ? ukr: eng;
  });

  const toggleLanguage = (lang) => {
    const newLanguage = lang === "ukr" ? ukr : eng;
    setLanguage(newLanguage);
    localStorage.setItem("language", lang); // Сохранение языка в localStorage
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);


