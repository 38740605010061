import { Box, Typography } from "@mui/material";

export default function CardPros({ props }) {
  const { title, description, icon, additional } = props;
  return (
    <Box
      className="pros-item-main-page"
      sx={{ width: { xs: "310px", sm: "370px" } }}
    >
      {icon}
      <Typography variant="h3" sx={{ mt: 3 }}>
        {title}
      </Typography>
      <Typography
        variant="body3"
        sx={{
          mt: 1,
          mb: "auto",
        }}
      >
        {description}
      </Typography>
      {additional && <Typography variant="body2" >{additional}</Typography>}
    </Box>
  );
}
