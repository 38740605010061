import Footer from "./partials/Footer";
import Header from "./partials/Header";
import { Box } from "@mui/material";
import { useTheme } from "./theme/themeContext";
import Room from "./partials/roomsPage/RoomCard";
import { useLanguage } from "./localization/languageContext";
import {
  roomPhotosLux,
  roomPhotosComfort,
  roomPhotosEconom,
} from "../photos/photosArray";
import MainPhotoSection from "./partials/MainPhotoSection";

export default function Rooms() {
  const { theme } = useTheme();
  const { language } = useLanguage();
  return (
    <>
      <Header />
      <MainPhotoSection>{language.roomsPage.title}</MainPhotoSection>
      <Box
        sx={{
          width: "100%",
          backgroundColor: theme.palette.primary.backgroundSecondary,
        }}
      >
        <Room
          props={{
            order: 1,
            price: 750,
            classRoom: language.roomsPage.economy.title,
            guestNum: 2,
            bedNum: 1,
            area: 14,
            roomPhotosArray: roomPhotosEconom,
            description: language.roomsPage.economy.description,
          }}
        />
        <Room
          props={{
            order: 2,
            price: 950,
            classRoom: language.roomsPage.comfort.title,
            guestNum: 2,
            bedNum: 1,
            area: 18,
            roomPhotosArray: roomPhotosComfort,
            description: language.roomsPage.comfort.description,
          }}
        />
        <Room
          props={{
            order: 1,
            price: 1250,
            classRoom: language.roomsPage.lux.title,
            guestNum: "1-4",
            bedNum: 2,
            area: 30,
            roomPhotosArray: roomPhotosLux,
            description: language.roomsPage.lux.description,
          }}
        />
      </Box>
      <Footer />
    </>
  );
}
