import { Box, Button, useMediaQuery } from "@mui/material";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { useTheme } from "./theme/themeContext";
import { photosArray } from "../photos/photosArray";
import { useLanguage } from "./localization/languageContext";
import { useState, useRef, useEffect } from "react";
import MainPhotoSection from "./partials/MainPhotoSection";

export default function Gallery() {
  const { theme } = useTheme();
  const { language } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [curPhoto, setCurPhoto] = useState(null);

  const modalRef = useRef(null);
  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isVerySmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  return (
    <Box className="wrapper">
      {showModal && (
        <Box
          onClick={handleClickOutside}
          className="flex-center"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(0, 0, 0, 0)",
          }}
        >
          <Box
            ref={modalRef}
            sx={{
              width: { xs: "100%", sm: "80%", md: "70%" },
              height: "max-content",
              backgroundColor: "#fff",
              padding: 1,
              borderRadius: 2,
              boxShadow: 24,
              zIndex: 10000,
              position: "sticky",
              top: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                boxSizing: "border-box",
                position: "relative",
              }}
            >
              {isSmall === false ? (
                isVerySmall === false ? (
                  <Button
                    onClick={() => setShowModal(false)}
                    variant="contained"
                    sx={{
                      zIndex: 2,
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                    }}
                  >
                    {language.galleryPage.closeButton}
                  </Button>
                ) : null
              ) : null}

              <img
                src={curPhoto}
                alt="Current "
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                  margin: 0,
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      <Header />

      <MainPhotoSection>{language.galleryPage.title}</MainPhotoSection>

      <Box
        sx={{
          backgroundColor: theme.palette.primary.backgroundSecondary,
          minHeight: "calc(100vh - 74px - 150px)",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gap: 0.5,
          padding: 2,
        }}
      >
        {photosArray.map((src, index) => {
          return (
            <Box
              className="gallery-image-cont"
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 1,
                boxSizing: "border-box",
              }}
            >
              <img
                onClick={() => {
                  setShowModal(true);
                  setCurPhoto(src);
                }}
                src={src}
                alt={`Slide ${index}`}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  margin: 0,
                }}
              />
            </Box>
          );
        })}
      </Box>

      <Footer />
    </Box>
  );
}
