import { Box, Typography } from "@mui/material";
import Icons from "../../MuiIcons/MuiIconsList";
import { useLanguage } from "../../localization/languageContext";
import Carousel from "../Carousel";

export default function Room({ props }) {
  const {
    order,
    price,
    classRoom,
    guestNum,
    bedNum,
    area,
    roomPhotosArray,
    description,
  } = props;
  const icons = Icons();
  const { language } = useLanguage();

  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
        },
        gap: 0.5,
        px: 2,
        py: 1,
        gridAutoFlow: "dense",
      }}
    >
      <Box
        sx={{
          order: { xs: 2, sm: order, md: order, lg: order },
          display: "flex",
          flexDirection: "column",
          gap: 3,
          py: 1,
          width: {
            xs: "100vw",
            sm: "calc(50vw - 21px)",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            px: { xs: 4, sm: 4, md: 10, lg: 16 },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
            }}
          >
            {language.roomsPage.miniTitleText}
          </Typography>
          <Box
            className="flex-center"
            sx={{
              flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
              gap: { lg: "0", md: "0", sm: "0", xs: 1.5 },
            }}
          >
            <Typography variant="h3">{classRoom}</Typography>
            <Typography
              variant="body1"
              sx={{
                ml: { lg: "auto", md: "auto", sm: "auto", xs: "0" },
                textAlign: "center",
              }}
            >
              {price > 1200 ? language.roomsPage.priceSec.fromText : null}{" "}
              <b>{price}</b> <br />{" "}
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                {language.roomsPage.priceSec.detail}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ borderTop: "2px solid  #80808065", width: "70%", m: "0 auto" }}
        ></Box>
        <Box
          className="flex-center"
          sx={{ gap: { lg: 2, md: 2, sm: 1, xs: 1.5 } }}
        >
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.guestIcon}
            <Typography variant="body2">
              {guestNum} {language.roomsPage.detailsSec.guestNumText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.roomQuantityIcon}
            <Typography variant="body2">
              1 {language.roomsPage.detailsSec.roomNumText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.areaIcon}
            <Typography variant="body2">
              {area} {language.roomsPage.detailsSec.areaText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.bedIcon}
            <Typography variant="body2">
              x{bedNum}{" "}
              {bedNum !== 1
                ? language.roomsPage.detailsSec.bedsText
                : language.roomsPage.detailsSec.bedText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.TVIcon}
            <Typography variant="body2">
              {language.roomsPage.detailsSec.tvText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {icons.roomIcons.drinkWater}
            <Typography variant="body2">
              {language.roomsPage.detailsSec.waterText}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: { xs: "center", sm: "left" },
            width: "70%",
            m: "0 auto",
          }}
        >
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "450px",
          width: {
            xs: "100vw",
            sm: "calc(50vw - 21px)",
          },
          order: {
            xs: 1,
            sm: order === 1 ? 2 : 1,
            md: order === 1 ? 2 : 1,
            lg: order === 1 ? 2 : 1,
          },
        }}
      >
        <Carousel slidesPerView={1}>{roomPhotosArray}</Carousel>
      </Box>
    </Box>
  );
}
