import photo_1 from "./galleryPhotos/photo_1.jpg";
import photo_2 from "./galleryPhotos/photo_2.jpg";
import photo_3 from "./galleryPhotos/photo_3.jpg";
import photo_4 from "./galleryPhotos/photo_4.jpg";
import photo_5 from "./galleryPhotos/photo_5.jpg";
import photo_6 from "./galleryPhotos/photo_6.jpg";
import photo_7 from "./galleryPhotos/photo_7.jpg";
import photo_8 from "./galleryPhotos/photo_8.jpg";
import photo_9 from "./galleryPhotos/photo_9.jpg";


//econom
import photo_econom_1 from "./roomPhotos/econom/photo_econom_1.jpg";
import photo_econom_2 from "./roomPhotos/econom/photo_econom_2.jpg";
import photo_econom_3 from "./roomPhotos/econom/photo_econom_3.jpg";
import photo_econom_4 from "./roomPhotos/econom/photo_econom_4.jpg";




//comfort
import photo_comfort_1 from "./roomPhotos/comfort/photo_comfort_1.jpg";
import photo_comfort_2 from "./roomPhotos/comfort/photo_comfort_2.jpg";
import photo_comfort_3 from "./roomPhotos/comfort/photo_comfort_3.jpg";
import photo_comfort_4 from "./roomPhotos/comfort/photo_comfort_4.jpg";
import photo_comfort_5 from "./roomPhotos/comfort/photo_comfort_5.jpg";
import photo_comfort_6 from "./roomPhotos/comfort/photo_comfort_6.jpg";
import photo_comfort_7 from "./roomPhotos/comfort/photo_comfort_7.jpg";
import photo_comfort_8 from "./roomPhotos/comfort/photo_comfort_8.jpg";
import photo_comfort_9 from "./roomPhotos/comfort/photo_comfort_9.jpg";



// lux
import photo_lux_1 from "./roomPhotos/lux/photo_lux_1.jpg";
import photo_lux_2 from "./roomPhotos/lux/photo_lux_2.jpg";
import photo_lux_3 from "./roomPhotos/lux/photo_lux_3.jpg";
import photo_lux_4 from "./roomPhotos/lux/photo_lux_4.jpg";
import photo_lux_5 from "./roomPhotos/lux/photo_lux_5.jpg";
import photo_lux_6 from "./roomPhotos/lux/photo_lux_6.jpg";
import photo_lux_7 from "./roomPhotos/lux/photo_lux_7.jpg";
import photo_lux_8 from "./roomPhotos/lux/photo_lux_8.jpg";
import photo_lux_9 from "./roomPhotos/lux/photo_lux_9.jpg";
import photo_lux_10 from "./roomPhotos/lux/photo_lux_10.jpg";
import photo_lux_11 from "./roomPhotos/lux/photo_lux_11.jpg";


export const photosArray = [
  photo_1,
  photo_2,
  photo_3,
  photo_4,
  photo_5,
  photo_6,
  photo_7,
  photo_8,
  photo_9
];

export const roomPhotosLux = [
  photo_lux_1,
  photo_lux_2,
  photo_lux_3,
  photo_lux_4,
  photo_lux_5,
  photo_lux_6,
  photo_lux_7,
  photo_lux_8,
  photo_lux_9,
  photo_lux_10,
  photo_lux_11,
];

export const roomPhotosComfort = [
  photo_comfort_1,
  photo_comfort_2,
  photo_comfort_3,
  photo_comfort_4,
  photo_comfort_5,
  photo_comfort_6,
  photo_comfort_7,
  photo_comfort_8,
  photo_comfort_9,
];

export const roomPhotosEconom = [
  photo_econom_1,
  photo_econom_2,
  photo_econom_3,
  photo_econom_4,
];