import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";

import { Box } from "@mui/material";

export default function Carousel({slidesPerView,children}) {
  return (
    <Swiper
      style={{ height: "100%", width: "100%" }}
      modules={[Navigation, Pagination]}
      slidesPerView={slidesPerView}
      navigation
      pagination={{
        clickable: true,
      }}
    >
      {children.map((photo, index) => (
        <SwiperSlide key={index}>
          <Box
            className="flex-center"
            key={index}
            sx={{
              height: "100%",
              width: "100%",
              userSelect: "none",
              boxSizing: "border-box",
            }}
          >
            <img
              src={photo}
              style={{
                width: "100%",
                objectFit: "contain",
                margin: 0,
              }}
              alt={`Slide ` + index}
            />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
