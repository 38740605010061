import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./components/theme/themeContext";
import Main from "./components/Main";
import Contacts from "./components/Contacts";
import Rules from "./components/Rules";
import Gallery from "./components/Gallery";
import Rooms from "./components/Rooms";
import { LanguageProvider } from "./components/localization/languageContext";


function App() {


  return (
    <LanguageProvider>
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<>404 - Page Not Found</>} />
        </Routes>
      </Router>
    </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
