import { Box, Typography } from "@mui/material";
import { useTheme } from "../theme/themeContext";
  

export default function MainPhotoSection({children}){
    const { theme } = useTheme();
    return <Box
    className="component-main-photo"
    sx={{
      backgroundAttachment: { xs: "scroll", md: "fixed" },
    }}
  >
    <Box
      className="component-main-text"
      sx={{
        width: { xs: "320px", sm: "500px" },
        backgroundColor: theme.palette.primary.background,
        transition: "opacity 1s ease-out, background-color 2s ease",
      }}
    >
      <Typography variant="h1">{children}</Typography>
    </Box>
  </Box>
}