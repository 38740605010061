import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "../theme/themeContext";
import Icons from "../MuiIcons/MuiIconsList";
import { useLanguage } from "../localization/languageContext";

const {
  TelegramNegative,
  ViberNegative,
} = require("../../social-icons/SocialIcons");
export default function Footer() {
  const { theme } = useTheme();
  const icons = Icons();
  const {language} = useLanguage()
  return (
    <Box
      component="footer"
      sx={{
        height: { xs: 310, sm: 200, md: 200, lg: 150 },
        backgroundColor: theme.palette.primary.backgroundSecondary,
        px: { sm: 2, md: 6, lg: 10 },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: { xs: "center", sm: "space-around" },
        gap: { xs: 1, sm: 0 },
        position: "relative",
      }}
    >
      <Box
        className="flex-center"
        sx={{
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Sacramento",
            fontSize: "24px",
            scale: 1.5,
            mb: 2,
          }}
        >
          Harmony
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icons.footerIcons.locationIcon}
          <Typography variant="body2">
            {language.footer.adress}
          </Typography>
        </Box>
        {/* autograph */}
        <Box sx={{ position: "absolute", right: 10, bottom: 0 }}>
          <Typography variant="body2" sx={{ fontSize: 12, opacity: 0.4 }}>
            developed by kushii
          </Typography>
        </Box>
      </Box>
      {/* contact zone */}
      <Box
        className="flex-center"
        sx={{
          flexDirection: "column",
        }}
      >
        <Box
          className="flex-center"
          sx={{
            gap: { xs: 1, sm: 2 },
            mb: 1,
            flexDirection: {xs:"column", lg: "row" },
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {icons.footerIcons.phoneIcon}
            <Box
              className="footer-links-cont flex-center"
              sx={{ flexDirection: "column" }}
            >
              <Link href="tel:+380935096949">+38 (093) 509 69 49</Link>
              <Link href="tel:+380995262777">+38 (099) 526 27 77</Link>
            </Box>
          </Box>
          {/* mail     */}
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 1.4,
              ml: {xs:0, sm:2} ,
            }}
          >
            {icons.footerIcons.emailIcon}
            <Box className="footer-links-cont flex-center">
              <Link href="mailto:hotelharmony.kyiv@gmail.com">hotelharmony.kyiv@gmail.com</Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.primary.color}`,
            pt: 1.5,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1.5,
            width: "110%",
          }}
        >
          <Typography variant="body1">{language.footer.socialMedia} : </Typography>
          <Box
            sx={{ display: "inline-flex", gap: .5 ,scale:"0.9"}}
            className="footer-social-links-section"
          >
            <Link href="https://t.me/TopHote">
              <TelegramNegative color={theme.palette.primary.color} />
            </Link>

            <Link href="https://msng.link/o?380935096949=vi">
              <ViberNegative color={theme.palette.primary.color} />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
