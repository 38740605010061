import { Box, Typography } from "@mui/material";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { useTheme } from "./theme/themeContext";
import { useLanguage } from "./localization/languageContext";
import MainPhotoSection from "./partials/MainPhotoSection";

export default function Rules() {
  const { theme } = useTheme();
  const { language } = useLanguage();
  return (
    <>
      <Header />
      <Box
        className="component-main-photo"
        sx={{
          backgroundAttachment: { xs: "scroll", md: "fixed" },
        }}
      >
        <MainPhotoSection>{language.rulesPage.title}</MainPhotoSection>
      </Box>
      <Box
        sx={{
          p: 4,
          backgroundColor: theme.palette.primary.background,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line1}
          <br />
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line1_1}
          <br />
          {language.rulesPage.rulesText.line1_2}
          <br />
          {language.rulesPage.rulesText.line1_3}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line2}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line2_1}
          <br />
          {language.rulesPage.rulesText.line2_2}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line3}
          <br />
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line3_1}
          <br />
          {language.rulesPage.rulesText.line3_2}
          <br />
          {language.rulesPage.rulesText.line3_3}
          <br />
          {language.rulesPage.rulesText.line3_4}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line4}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line4_1}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line5}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line5_1}
          <br />
          {language.rulesPage.rulesText.line5_2}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line6}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line6_1}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line7}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line7_1}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line8}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line8_1}
          <br />
          {language.rulesPage.rulesText.line8_2}
          <br />
          <br />
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
          {language.rulesPage.rulesText.line9}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", fontSize: "1rem", lineHeight: 1.5 }}
        >
          {language.rulesPage.rulesText.line9_1}
          <br />
          {language.rulesPage.rulesText.line9_2}
          <br />
          {language.rulesPage.rulesText.line9_3}
          <br />
          <br />
        </Typography>
      </Box>

      <Footer />
    </>
  );
}
